/*******************************************************************************

COPYRIGHT © 2024 IWDG. ALL RIGHTS RESERVED.

This file, and the accompanying materials, are proprietary to and constitute valuable trade secrets of the IWDG. 
Unauthorized use, reproduction, or distribution of this file or its contents is strictly prohibited without prior written consent from the IWDG.

Created: 01/06/2024 with support from the EU Horizon funded OCEAN Project.

Author: The Irish Whale and Dolphin Group (IWDG).

*******************************************************************************/
import { createStore } from "vuex";
import db from "@/db";
//import geolocation from "@/geolocation";

export default createStore({
  state: {
    geoLoc: {
      latitude: 0,
      longitude: 0,
      speed: 0,
      heading: 0,
      locationFound: false,
    },
    settings: {
      SettingId: null,
      Name: null,
      Email: null,
      Range: null,
      Angle: 360,
      ApiEndpoint: null,
      Token: null,
      isDarkMode: false,
      showUserData: true,
    },
    reportData: {
      reportID: null,
      deviceId: null,
      firstName: null,
      lastName: null,
      emailAddress: null,
      vesselName: null,
      vesselIMO: null,
      vesselLat: null,
      vesselLong: null,
      objectLat: null,
      objectLong: null,
      timeOfObservation: null,
      vesselSpeed: null,
      vesselHeading: null,
      speedOfObject: null,
      objectType: null,
      deadOrAlive: null,
      distanceToObject: null,
      numberOfAnimals: null,
      lengthOfLargestAnimal: null,
      blowsVisible: null,
      behaviour: null,
      species: null,
      containerSize: null,
      numberOfContainers: null,
      containerType: null,
      containerIDs: null,
      containerColour: null,
      containerStatus: null,
      numberObjects: null,
      lengthOfLargestObject: null,
      colourObject: null,
      descriptionObjects: null,
      synced: null,
    },
    deferredPrompt: null,
    showInstallPopup: false,
  },
  getters: {
    currentSettings: state => state.settings,
  },
  mutations: {
    setDeferredPrompt(state, prompt) {
      state.deferredPrompt = prompt;
    },
    setShowInstallPopup(state, value) {
      state.showInstallPopup = value;
    },
    SET_AUTH_CONFIRMED(state, value) {
      state.settings.Sized = value;
    },
    toggleDarkMode(state) {
      console.log(
        "Current isDarkMode before toggle:",
        state.settings.isDarkMode
      );
      state.settings.isDarkMode = !state.settings.isDarkMode;
    },
    setWatchId(state, watchId) {
      state.geoLoc.watchId = watchId;
    },
    setLocationFound(state, found) {
      state.geoLoc.locationFound = found;
    },
    setGeolocationError(state, error) {
      state.geoLoc.geolocationError = error;
    },
    updateLocation(state, { latitude, longitude, speed, heading }) {
      state.geoLoc.latitude = latitude;
      state.geoLoc.longitude = longitude;
      state.geoLoc.speed = speed;
      //state.geoLoc.heading = heading;
    },
    updateReportWhaleData(state, payload) {
      state.reportData = { ...state.reportData, ...payload };
    },
    updateReportContainerData(state, payload) {
      state.reportData = { ...state.reportData, ...payload };
    },
    updateSettings(state, payload) {
      state.settings = payload;
    },
  },
  actions: {
    toggleUserDataEnabled({ commit }, value) {
      commit("setUserDataEnabled", value);
    },
    async fetchSettings({ commit }) {
      try {
        const settings = await getSettingsFromDB(); // Fetch settings data
        commit("updateSettings", settings); // Commit to Vuex store

        //console.log("Settings Here:", JSON.stringify(settings, null, 2)); // Log the fetched settings as a string
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    },
    async updateSettings({ commit }, newSettings) {
      try {
        await db.Settings.update(newSettings.SettingId, newSettings);
        commit("updateSettings", newSettings);
      } catch (error) {
        console.error("Error updating settings:", error);
      }
    },
    saveReportData({ commit }, reportData) {
      commit("updateReportData", reportData);
    },
    async submitReport({ state }) {
      const combinedData = {
        ...state.reportData,
        ...state.settings,
      };

      // Perform the API call
      // axios.post('your-api-endpoint', combinedData)...
    },
  },
  modules: {},
});

async function getSettingsFromDB() {
  const settings = await db.Settings.toArray();
  return settings[0];
}
