/*******************************************************************************
COPYRIGHT © 2024 IWDG. ALL RIGHTS RESERVED. This file, and the accompanying
materials, are proprietary to and constitute valuable trade secrets of the IWDG.
Unauthorized use, reproduction, or distribution of this file or its contents is
strictly prohibited without prior written consent from the IWDG. Created:
01/06/2024 with support from the EU Horizon funded OCEAN Project. Author: The
Irish Whale and Dolphin Group (IWDG).
*******************************************************************************/
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
//import { useGeolocation } from "@/geolocation";

export default {
  name: "OCEAN",
  async created() {
    await this.$store.dispatch("fetchSettings");
  },
  computed: {
    ...mapState({
      latitude: state => state.geoLoc.lat,
      longitude: state => state.geoLoc.lng,
      speed: state => state.geoLoc.speed,
      heading: state => state.geoLoc.heading,
    }),
  },
  mounted() {
    //const { checkPermissionAndTrack } = useGeolocation();
    //checkPermissionAndTrack();
    // /startTracking();
  },
  beforeUnmount() {
    //const { stopTracking } = useGeolocation();
    //stopTracking();
  },
};
</script>
