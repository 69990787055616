/*******************************************************************************
COPYRIGHT © 2024 IWDG. ALL RIGHTS RESERVED. This file, and the accompanying
materials, are proprietary to and constitute valuable trade secrets of the IWDG.
Unauthorized use, reproduction, or distribution of this file or its contents is
strictly prohibited without prior written consent from the IWDG. Created:
01/06/2024 with support from the EU Horizon funded OCEAN Project. Author: The
Irish Whale and Dolphin Group (IWDG).
*******************************************************************************/
<template>
  <div>
    <main>
      <router-view></router-view>
    </main>

    <!-- App Sidebar -->
    <div class="offcanvas offcanvas-start" tabindex="-1" id="sidebarPanel">
      <div class="offcanvas-body">
        <!-- profile box -->
        <div class="profileBox">
          <div class="image-wrapper">
            <img
              :src="`${baseUrl}img/logo.png`"
              alt="image"
              class="imaged"
              style="height: 40px"
            />
          </div>

          <a href="#" class="close-sidebar-button" data-bs-dismiss="offcanvas">
            <ion-icon name="close"></ion-icon>
          </a>
        </div>
        <!-- * profile box -->

        <div class="" style="margin-top: 10px; margin-bottom: 20px">
          <ul class="listview flush transparent no-line image-listview mt-2">
            <li>
              <router-link to="/main/map" class="item" @click="closeSidebar">
                <div class="icon-box bg-default">
                  <ion-icon
                    name="home-outline"
                    role="img"
                    class="md hydrated"
                    aria-label="home outline"
                  ></ion-icon>
                </div>

                <div class="section-2">
                  <span class="in">Home</span>
                  <div class="section-3"></div>
                </div>
              </router-link>
            </li>
            <!--
            <li>
              <router-link
                to="/main/viewData"
                class="item"
                @click="closeSidebar"
              >
                <div class="icon-box bg-default">
                  <ion-icon
                    name="folder-outline"
                    role="img"
                    class="md hydrated"
                    aria-label="home outline"
                  ></ion-icon>
                </div>
                <div class="box">
                  <span class="in">View Server Data</span>
                </div>
              </router-link>
            </li>

            <li>
              <router-link
                to="/main/viewDataLocal"
                class="item"
                @click="closeSidebar"
              >
                <div class="icon-box bg-default">
                  <ion-icon
                    name="folder-outline"
                    role="img"
                    class="md hydrated"
                    aria-label="home outline"
                  ></ion-icon>
                </div>
                <div class="box">
                  <span class="in">View Local Data</span>
                </div>
              </router-link>
            </li>
-->
          </ul>
        </div>
        <div class="">
          <div class="box-4"></div>
          <div class="">
            <ul class="listview flush transparent no-line image-listview mt-2">
              <!-- <li>
                <router-link to="/main/map" class="item">
                  <div class="icon-box bg-default">
                    <ion-icon
                      name="help-outline"
                      role="img"
                      class="md hydrated"
                      aria-label="home outline"
                    ></ion-icon>
                  </div>

                  <div class="section-2">
                    <span class="in">Help</span>
                  </div>
                </router-link>
              </li> -->

              <li>
                <router-link
                  to="/main/settings"
                  class="item"
                  @click="closeSidebar"
                >
                  <div class="icon-box bg-default">
                    <ion-icon
                      name="settings-outline"
                      role="img"
                      class="md hydrated"
                      aria-label="home outline"
                    ></ion-icon>
                  </div>

                  <div class="section-2">
                    <span class="in">Settings</span>
                  </div>
                </router-link>
              </li>
              <li>
                <div class="item">
                  <div class="icon-box bg-default">
                    <ion-icon
                      name="moon-outline"
                      role="img"
                      class="md hydrated"
                      aria-label="moon outline"
                    ></ion-icon>
                  </div>

                  <div class="section-2">
                    <div class="in">
                      <div>Dark Mode</div>
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input dark-mode-switch"
                          type="checkbox"
                          id="darkmodesidebar"
                          v-model="isDarkMode"
                        />
                        <label
                          class="form-check-label"
                          for="darkmodesidebar"
                        ></label>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <router-link to="/" class="item no-arrow" @click="logOut">
                  <div class="icon-box bg-default">
                    <ion-icon
                      name="settings-outline"
                      role="img"
                      class="md hydrated"
                      aria-label="home outline"
                    ></ion-icon>
                  </div>

                  <div class="section-2">
                    <span class="in">LOG OUT</span>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
          <!--
            <div class="group-7">
              <div class="img-5">
                <img :src="`${baseUrl}img/icon/alerts.svg`" alt="alerts" />
              </div>
              <div class="group-8">
                <span class="text-7">Alerts</span>
                <div class="section-9"></div>
              </div>
            </div>
            -->
        </div>
        <div class="wrapper-5"></div>
        <div class="group-9">
          <div class="box-5">
            <div class="wrapper-6"><span class="text-8">9</span></div>
            <div class="pic-3"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- * App Sidebar -->
  </div>
</template>

<script>
import Settings from "@/views/Settings.vue";
import { mapState } from "vuex";
import db from "@/db";

export default {
  computed: {
    isDarkMode: {
      get() {
        return this.$store.state.settings.isDarkMode;
      },
      set(value) {
        this.$store.commit("toggleDarkMode");
      },
    },
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      isDarkMode: false,
    };
  },
  watch: {
    isDarkMode(newVal) {
      localStorage.setItem("darkMode", newVal);
      this.toggleDarkMode(newVal);
    },
  },
  methods: {
    async logOut() {
      console.log("Logging out...");
      await db.Settings.update(1, { Sized: false, Layout: "" });
      this.$store.commit("SET_AUTH_CONFIRMED", false);
      this.$router.push("/");
    },
    closeSidebar() {
      let sidebar = document.getElementById("sidebarPanel");
      if (sidebar) {
        let bsOffcanvas = bootstrap.Offcanvas.getInstance(sidebar);
        bsOffcanvas.hide();
      }
    },
    toggleDarkMode(isEnabled) {
      //const leafletLayers = document.querySelectorAll(".leaflet-layer");
      const topBar = document.querySelector(".ob-nav-top-bar");
      if (isEnabled) {
        document.body.classList.add("dark-mode-active", "body-dark-mode");
        if (topBar) {
          topBar.style.backgroundColor = "#777"; // Dark mode color
          topBar.style.color = "#fff"; // Optional: if text color needs to change
        }
      } else {
        document.body.classList.remove("dark-mode-active", "body-dark-mode");
        if (topBar) {
          topBar.style.backgroundColor = ""; // Reset to original color
          topBar.style.color = ""; // Optional: reset text color
        }
      }
    },
  },
  mounted() {
    const savedDarkMode = localStorage.getItem("darkMode") === "true";
    this.isDarkMode = savedDarkMode;
    this.toggleDarkMode(this.isDarkMode);
  },
  beforeDestroy() {
    document.body.classList.remove("dark-mode-active");
  },
};
</script>
<style>
.no-arrow::after {
  content: none !important;
}
</style>
