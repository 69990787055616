import axios from "axios";

const VUE_APP_BACKEND_API_BASE_UR = process.env.VUE_APP_BACKEND_API_BASE_URL;
const APIAUTH_URL = VUE_APP_BACKEND_API_BASE_UR + "authentication";

export const authService = {
  async register(username, password, key, confirmed) {
    try {
      const response = await axios.post(
        `${APIAUTH_URL}/register`,
        {
          Email: username,
          Password: password,
          Key: key,
          Confirmed: confirmed,
        },
        { withCredentials: true }
      );

      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
      }

      if (response.data.Key) {
        localStorage.setItem("ENHIKey", response.data.Key);
      }

      return { success: true, message: "Registered successfully!" };
    } catch (error) {
      return { success: false, message: error.message };
    }
  },

  async login(username, password) {
    console.log("Sending login request:", {
      Email: username,
      Password: password,
    });
    try {
      debugger;
      const response = await axios.post(
        `${APIAUTH_URL}/login`,
        { Email: username, Password: password },
        { withCredentials: true }
      );
      console.log("Login response:", response.data);

      if (response.data.key) {
        localStorage.setItem("token", response.data.key);
      }

      return {
        success: true,
        key: response.data.key,
        message: "Logged in successfully!",
      };
    } catch (error) {
      console.error("Login error:", error.response || error.message);
      return { success: false, message: error.message };
    }
  },

  async refreshToken() {
    try {
      const response = await axios.post(
        `${APIAUTH_URL}/refresh`,
        {},
        { withCredentials: true }
      );

      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
      }

      if (response.data.key) {
        localStorage.setItem("ENHIKey", response.data.key);
      }

      return response.data.token;
    } catch (error) {
      throw new Error("Failed to refresh token.");
    }
  },

  isTokenExpired(token) {
    try {
      const payload = JSON.parse(atob(token.split(".")[1]));
      return payload.exp * 1000 < Date.now();
    } catch (error) {
      console.error("Error checking token expiration:", error.message);
      return true;
    }
  },

  getToken() {
    return localStorage.getItem("token");
  },
};
