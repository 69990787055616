/*******************************************************************************

COPYRIGHT © 2024 IWDG. ALL RIGHTS RESERVED.

This file, and the accompanying materials, are proprietary to and constitute valuable trade secrets of the IWDG. 
Unauthorized use, reproduction, or distribution of this file or its contents is strictly prohibited without prior written consent from the IWDG.

Created: 01/06/2024 with support from the EU Horizon funded OCEAN Project.

Author: The Irish Whale and Dolphin Group (IWDG).

*******************************************************************************/

import Dexie from "dexie";
const db = new Dexie("Ocean_DB");

//DELETE DATABASE
//const dbName = 'Ocean_DB'
//const request = indexedDB.deleteDatabase(dbName)
//await db.delete();
db.version(8).stores({
  Sighting:
    "reportID, deviceId, firstName, lastName, emailAddress, vesselName, vesselIMO, vesselLat, vesselLong, objectLat, objectLong, timeOfObservation, vesselSpeed, vesselHeading, speedOfObject, objectType, deadOrAlive, distanceToObject, numberOfAnimals, lengthOfLargestAnimal, blowsVisible, behaviour, species, containerSize, numberOfContainers, containerType, containerIDs, containerColour, containerStatus, numberObjects, lengthOfLargestObject, colourObject, descriptionObjects , synced, geoJsonPayload, sentOcean,sentBackend",
  Warnings:
    "ID,validFromTS,expiresMins,warningType,description,lat,long,warningLevel",
  ErrorLog:
    "ErrorId, ErrorDescription, FormName, UserId, UserName, CustomerId, TheDateTime",
  Settings:
    "SettingId, Name, Email, Layout, Range, Angle, ApiEndpoint, Token, isDarkMode, showUserData, Sized",
  ATON: "id, geoJsonData, timestamp, description",
  tiles: "key,blob",
});

if (!db.isOpen()) {
  db.open()
    .then(initializeDefaultValues)
    .catch(err => {
      console.error(`Open failed: ${err.stack}`);
    });
}

// Handle 'blocked' and 'versionchange' events
db.on("blocked", () => {
  console.warn("Database opening blocked by another connection.");
});

db.on("versionchange", event => {
  alert("A new version of the app is available. Please reload the page.");
  db.close();
});

async function initializeDefaultValues() {
  try {
    const hasSettings = await db.Settings.count();
    if (hasSettings === 0) {
      await db.Settings.bulkPut([
        {
          SettingId: 1,
          Name: "Default Name",
          Email: "",
          Layout: null,
          Range: 3,
          Angle: 120,
          ApiEndpoint: "https://ocean.evolve-red.com/api/",
          Token: "default-token",
          isDarkMode: false,
          showUserData: true,
          Sized: false,
        },
        // ... other default settings
      ]);
    }
  } catch (error) {
    console.error("Failed to initialize default values:", error);
  }
}

export default db;
